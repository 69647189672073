exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attorney-sanity-attorney-slug-current-js": () => import("./../../../src/pages/attorney/{sanityAttorney.slug__current}.js" /* webpackChunkName: "component---src-pages-attorney-sanity-attorney-slug-current-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-criminal-law-js": () => import("./../../../src/pages/criminal-law.js" /* webpackChunkName: "component---src-pages-criminal-law-js" */),
  "component---src-pages-criminal-law-sanity-practice-area-page-slug-current-js": () => import("./../../../src/pages/criminal-law/{sanityPracticeAreaPage.slug__current}.js" /* webpackChunkName: "component---src-pages-criminal-law-sanity-practice-area-page-slug-current-js" */),
  "component---src-pages-immigration-law-js": () => import("./../../../src/pages/immigration-law.js" /* webpackChunkName: "component---src-pages-immigration-law-js" */),
  "component---src-pages-immigration-law-sanity-practice-area-page-slug-current-js": () => import("./../../../src/pages/immigration-law/{sanityPracticeAreaPage.slug__current}.js" /* webpackChunkName: "component---src-pages-immigration-law-sanity-practice-area-page-slug-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "slice---src-components-footer-footer-js": () => import("./../../../src/components/Footer/footer.js" /* webpackChunkName: "slice---src-components-footer-footer-js" */),
  "slice---src-components-header-header-js": () => import("./../../../src/components/Header/header.js" /* webpackChunkName: "slice---src-components-header-header-js" */)
}

